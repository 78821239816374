import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import {
  StyledModal,
  StyledTitle,
  StyledBody,
} from "../../EngagementSectionV2/CompanyInitiative/styles";
import { ImageUrl } from "../../../utils/constants";
import styled from "styled-components";
import { CommonCloseIcon } from "../../../utils/icons";

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.isSelected ? "#007AB1" : "var(--navy-blue-5, rgba(0, 92, 135, 0.05))"};
  border: 4px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  margin: 8px;

  img {
    width: 36px;
    height: 36px;
  }
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

class CoreValuesIconsPopup extends React.Component {


  handleIconClick = (icon) => {
    this.props.onIconSelect(icon); // Call parent callback to set selected icon
  };

  render() {
    const { showModal, onClose, iconData, selectedIcon } = this.props;

    return (
      <StyledModal
        transform={1}
        show={showModal}
        newWidth="800px"
        newBg="newBg"
        height="50px"
        display="none"
      >
        <Modal.Header closeButton onHide={onClose} style={{ background: "white" }}>
          <StyledTitle
            style={{
              color: "#005C87",
              width: "100%",
              fontSize: "18px",
              justifyContent: "flex-start",
              marginLeft: "40%",
            }}
          >
            Choose An Icon
            <div
              onClick={onClose}
              style={{
                display: "flex",
                margin: "auto 0 auto auto",
                cursor: "pointer",
              }}
            >
              {CommonCloseIcon()}
            </div>
          </StyledTitle>
        </Modal.Header>
        <StyledBody style={{ padding: "24px" }}>
          <IconGrid>
            {iconData && iconData.length > 0 ? (
              iconData.map((icon, index) => (
                <IconWrapper
                  key={index}
                  isSelected={selectedIcon === icon}
                  onClick={() => this.handleIconClick(icon)}
                >
                  <img
                    src={`${ImageUrl}/${
                      selectedIcon === icon ? icon.white_icon : icon.blue_icon
                    }`}
                    alt="Icon"
                  />
                </IconWrapper>
              ))
            ) : (
              <p>No icons available</p>
            )}
          </IconGrid>
        </StyledBody>
      </StyledModal>
    );
  }
}

CoreValuesIconsPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  iconData: PropTypes.array,
  onIconSelect: PropTypes.func.isRequired,
  selectedIcon: PropTypes.object, 
};

export default CoreValuesIconsPopup;
